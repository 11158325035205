import './index.scss'
export default function Platform({ platformData: { title, abstract, content, list } }) {
  return (
    <div className="platform page-style">
      <div className="bag"></div>
      <p className="p1">{title}</p>
      <p className="p2">{abstract}</p>
      <p className="p3">
        {content}</p>
      <div className="list w-full">
        {
          list.map(item => (
            <div className="list-item" key={item.id}>
              <p>
                <span className="big">{item.value}</span>
                <span className="span1">{item.title}</span>
              </p>
              <p className="span2">{item.content}</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}