import Footer from "../components/Footer/Footer";
import './index.scss'
import ReactFullpage from '@fullpage/react-fullpage';
import FiveBox from "../components/FiveBox/FiveBox";
function Page1() {
  return (
    <div className="page-1 h-screen page-style">
      <p className="p1">让每个人都受益于 AI</p>
      <p className="p2">星耀的使命一直是创新AI应用，使人人都可访问并从中受益。</p>
      <p className="p3">......</p>
    </div>
  )
}
function Page2() {
  return (
    <div className="page-2 page-style h-full">
      <p className='p1'>技术优势</p>
      <p className="p2">让数字人像真人一样学习、生活、互动、打造真正属于他们的世界！</p>
      <div className="content w-full">
        <div className="tag1">
          <img src="http://image.wuyougoodcar.com/renhuo/2023-06-29/OIzhhdtLD15kiuTFz5z4g32skjBIXyqBPcX7Iw5E.png"></img>
          <p>计算机</p>
          <img src="http://image.wuyougoodcar.com/renhuo/2023-06-29/OIzhhdtLD15kiuTFz5z4g32skjBIXyqBPcX7Iw5E.png"></img>
        </div>
        <div className="tag2">
          <img src="http://image.wuyougoodcar.com/renhuo/2023-06-29/OIzhhdtLD15kiuTFz5z4g32skjBIXyqBPcX7Iw5E.png"></img>
          <p>神经渲染</p>
          <img src="http://image.wuyougoodcar.com/renhuo/2023-06-29/OIzhhdtLD15kiuTFz5z4g32skjBIXyqBPcX7Iw5E.png"></img>
        </div>
        <div className="tag3">
          <img src="http://image.wuyougoodcar.com/renhuo/2023-06-29/OIzhhdtLD15kiuTFz5z4g32skjBIXyqBPcX7Iw5E.png"></img>
          <p>实时交互</p>
          <img src="http://image.wuyougoodcar.com/renhuo/2023-06-29/OIzhhdtLD15kiuTFz5z4g32skjBIXyqBPcX7Iw5E.png"></img>
        </div>
        <img className="main-img" src="http://image.wuyougoodcar.com/renhuo/2023-06-29/pRwXmNrcZIb1PEOjXimhcY5wknJHg1jZ6pDCuubj.png"></img>
        <div className="tag4">
          <img src="http://image.wuyougoodcar.com/renhuo/2023-06-29/WjTViz1pIdnoK2IXgxVyS98JY1SPslupLml0axhy.png"></img>
          <p>小模型垂直训练</p>
          <img src="http://image.wuyougoodcar.com/renhuo/2023-06-29/WjTViz1pIdnoK2IXgxVyS98JY1SPslupLml0axhy.png"></img>
        </div>
        <div className="tag5">
          <img src="http://image.wuyougoodcar.com/renhuo/2023-06-29/WjTViz1pIdnoK2IXgxVyS98JY1SPslupLml0axhy.png"></img>
          <p>人工智能</p>
          <img src="http://image.wuyougoodcar.com/renhuo/2023-06-29/WjTViz1pIdnoK2IXgxVyS98JY1SPslupLml0axhy.png"></img>
        </div>
        <div className="tag6">
          <img src="http://image.wuyougoodcar.com/renhuo/2023-06-29/WjTViz1pIdnoK2IXgxVyS98JY1SPslupLml0axhy.png"></img>
          <p>计算机图形化</p>
          <img src="http://image.wuyougoodcar.com/renhuo/2023-06-29/WjTViz1pIdnoK2IXgxVyS98JY1SPslupLml0axhy.png"></img>
        </div>
      </div>
    </div>
  )
}

export default function Technology() {
  const fiveBoxData = {
    title:'5大核心',
    abstract:'星耀五大核心技术奠定了全球商业化智能交互领域的优势地位',
    list:[
      {id:1,title:"让数字人和我们一样，拥有真实的形象",url:'http://image.wuyougoodcar.com/renhuo/2023-06-29/WKtB23BPZOW7g9WX3HMHDL2ndQG6Md0AvpzqyKUh.png'},
      {id:2,title:"让数字人和您一样思考、互动",url:'http://image.wuyougoodcar.com/renhuo/2023-06-29/jJoCFVdzpRZ9zTmf5fuMdSg4QdrSSkV0MKSTsFWm.png'},
      {id:3,title:"为数字人创造实时高效的“工作环境”",url:'http://image.wuyougoodcar.com/renhuo/2023-06-29/68M0Fa2nUEwRjN5M345gsF2GpRDU5l5tF68qgvfr.png'},
      {id:4,title:"让数字人的对话更加自然流利",url:'http://image.wuyougoodcar.com/renhuo/2023-06-29/TT9SGkWi8XWykJ8MFD5oiycI5uAcAaLs49kTIcgw.png'},
      {id:5,title:"实时互动，让数字人随时随地与您建立连接",url:'http://image.wuyougoodcar.com/renhuo/2023-06-29/djqw28vAzUuoZ93I5EqpXTAnjMTUsP78MeNeuNS3.png'},
    ]
  }
  const windowWidth = window.innerWidth
  return (
    <div className="hidden-scroll technology-container">
      <ReactFullpage licenseKey="OPEN-SOURCE-GPLV3-LICENSE" render={(state) => {
        if (state.fullpageApi){
          state.fullpageApi.setScrollingSpeed(1)
          state.fullpageApi.moveTo(1)
          state.fullpageApi.setScrollingSpeed(700)
        } 
        return (
          <ReactFullpage.Wrapper>
            <div className="container-first section">
              <Page1></Page1>
            </div>
            <div className="section">
              <Page2></Page2>
            </div>
            <div className="section">
              <FiveBox fiveBoxData={fiveBoxData}></FiveBox>
            </div>
            {
              windowWidth >= 996 ? <div className="section section-footer">
                <Footer></Footer>
              </div>:
              <div className="section">
                <Footer></Footer>
              </div>
            }
          </ReactFullpage.Wrapper>
        )
      }}>

      </ReactFullpage>

    </div>
  )
}