import './index.scss'
export default function FiveBox({fiveBoxData:{title,abstract,list}}){
  return(
    <div className="five-box h-screen page-style">
      <p className="p1">{title}</p>
      <p className="p2">{abstract}</p>
      <div className="list w-full">
        {
          list.map(item=>{
            return <div key={item.id} className={`list-item item${item.id}`}>
              <img src={item.url} />
              <p>{item.title}</p>
            </div>
          })
        }
      </div>
    </div>
  )
}