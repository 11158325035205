import './index.scss'
export default function Buy({ handlerShow, type }) {
  const close = (e) => {
    e.stopPropagation()
    handlerShow()
  }
  return (
    <div className='mask-box' onClick={close} style={{ position: type ? 'absolute' : 'fixed' }}>
      <div className='qr-box'>
        <p className='p1'>扫码完成购买</p>
        <p className='p2'>微信扫码联系客服</p>
        <img className='qr-img' src="http://image.wuyougoodcar.com/renhuo/2023-07-11/Un9x6lirguDhmSILpXUPrzICgspkmfRSNWBIy4IA.png" alt="" />
        <p className='p3'>扫码失败可联系电话：17370044114</p>
      </div>
    </div>
  )
}