import axios from "axios";
// import baseurl from "./baseurl";
const baseurl = 'http://ai.wuyougoodcar.com/api'
// const baseurl = '/api'
axios.interceptors.request.use((config) => {
  config.data = JSON.stringify(config.data);
  config.headers["Content-Type"] = "application/json"
  return config;
}, (error) => Promise.reject(error))

axios.interceptors.response.use((response) => {
  return response;
}, (error) => console.log('请求出错：', error))

const request = ({url, method = "GET", data}) => {
  const baseURL = baseurl + url
  if (method === 'GET' || method === 'get') {
    return new Promise((reslove, reject) => {
      axios.get(baseURL, { params: data }).then(res => reslove(res.data))
    })
  } else if (method === 'POST' || method === 'post') {
    return new Promise((reslove, reject) => {
      axios.post(baseURL, data).then(res => reslove(res.data))
    })
  } else {
    throw Error('请使用‘GET’或‘POST’请求方式')
  }
}

export default request