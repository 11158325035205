import './index.scss'
import Platform from '../components/Platform/Platform';
import ReactFullpage from '@fullpage/react-fullpage';
import JoinUs from '../components/JoinUs/JoinUs';
import Footer from '../components/Footer/Footer';
import Cooperates from '../components/Cooperates/Cooperates';
function ToGPage1() {
  return (
    <div className="tog-page-1 h-screen page-style">
      <img className='logo' src='http://image.wuyougoodcar.com/renhuo/2023-06-29/htX9hM5KEtraQqXMd3SEJHDdtfnFLBvwyXJe1MOw.png'></img>
    </div>
  )
}
function ToGPage3() {
  return (
    <div className='tog-page-3 h-screen page-style'>
      <p className='p1'>多种IP类型提供定制化服务</p>
      <p className='p2'>针对当前政务痛点，使用AI数字人赋能政府，帮助政府更好服务人民</p>
      <div className='list'>
        <div className='item1'>
          卡通形象
        </div>
        <div className='item2'>
          AIGC
        </div>
        <div className='item3'>
          数字人
        </div>
        <div className='item4'>
          深度定制人
        </div>
      </div>
      <p className='p3'>AI数字人IP，解决政务项目落地困难痛点。</p>
    </div>
  )
}

function ToGPage5(){
  return (
    <div className='tog-page-5 page-style h-screen'>
      <p1 className="p1">应用场景</p1>
      <p1 className="p2">不同项目，角色可灵活切换，满足多集探索的需求。</p1>
      <div className='list'>
        <div className='list-item'>
          <img src='http://image.wuyougoodcar.com/renhuo/2023-07-08/qOHmFHSDztVHqIXVPgeArLavS6qNCuYcMkUQ8Rrp.png'></img>
          <p className='p3'>文旅</p>
        </div>
        <div className='list-item'>
          <img src='http://image.wuyougoodcar.com/renhuo/2023-07-08/Thn7S17krnjvf1NgpUvBaOMaa7rlT2G2BsoeNaFe.png'></img>
          <p className='p3'>政府</p>
        </div>
        <div className='list-item'>
          <img src='http://image.wuyougoodcar.com/renhuo/2023-07-08/aONayj9hNr5IgxSIC2n42iO6Dx7nTdJy8TAhRw34.png'></img>
          <p className='p3'>外贸</p>
        </div>
        <div className='list-item'>
          <img src='http://image.wuyougoodcar.com/renhuo/2023-07-08/UsQyhnql0slXY5Dt9LbE4oD7D5hXQV3r4XEs4rHA.png'></img>
          <p className='p3'>工业</p>
        </div>
        <div className='list-item'>
          <img src='http://image.wuyougoodcar.com/renhuo/2023-07-08/IFrf80nUuoaiR4EIB7jK3cFlWYczkTEYZ4CWkjsJ.png'></img>
          <p className='p3'>教育</p>
        </div>
        <div className='list-item'>
          <img src='http://image.wuyougoodcar.com/renhuo/2023-07-08/cJvJV3v5GUrK1yI0QMVxZ4Z5gAzllhGLuaIHxQ4X.png'></img>
          <p className='p3'>医疗</p>
        </div>
      </div>
    </div>
  )
}
export default function ToG() {
  const windowWidth = window.innerWidth

  const platformData = {
    title: '星耀AI数字平台',
    abstract: '星耀AI数字成立于2019年08月，总部位于中国杭州，是一家以服务智能化和知识智能化为核心的人工智能创新企业。',
    content: '星耀AI通过打造开放开源，集体进步，任劳任怨的星耀数字劳动力，为客户提供近乎无限供给的数字员工，大幅度降低组织成本，快速提升组织效率，让客户在数字化转型中拥有以算力为核心的创新竞争力。公司目前拥有多项授权专利，业务覆盖多个行业和近4万家企业，其中包括工商银行、中国银行、招商银行、浦发银行、兴业银行、中信银行、南京银行等众多大型金融机构。公司成立以来，以AI商业落地为核心，赢得众多投资机构的青睐，公司先后获得多轮融资，包括腾讯战投、招银国际、招银电信、国新央企、海松资本、红杉资本、浦信资本、中财奇虎等众多投资机构，也让星耀AI成为⼈⼯智能领域的一匹黑马。星耀AI大力提倡以数字化为根基的星耀文明，致力于将人类从繁重的重复性脑力劳动中解放出来，促进组织的碳达峰和碳中和，让人回归人的价值。',
    list: [
      {
        value: '15+', title: '技术专利', content: '15+技术专利，只为提供更专业的行业解决方案', id: 1
      },
      {
        value: '40000+', title: '覆盖行业', content: '40000+企业合作，让AI商业应用场景更为真实且广泛', id: 2
      },
      {
        value: '100+', title: '团队实力', content: '100+行业内精尖团队成员，以技术赋能更多AI商业应用落地', id: 3
      },
    ]
  }
  const cooperateData = {
    title: '3D数字人 匠心打造',
    abstract: '标准化流程，高精度塑造人物形态，细致雕琢任务细微表情、衣服褶皱等高难度细节',
    list: [
      { title: '01:概念设计', url:windowWidth>996? 'http://image.wuyougoodcar.com/renhuo/2023-07-08/mewYRVTmaM6miMBntJ76UA5W44gcmAKEmK6XMNv2.png':'http://image.wuyougoodcar.com/renhuo/2023-07-08/fLsnFFDaTNlWQir5FkCuLDtGyExloMK8X1jz81V1.png' },
      { title: '02:智能建模', url: windowWidth>996?'http://image.wuyougoodcar.com/renhuo/2023-07-08/GZgzW00LeVtd8oIvAqD7OvHM2RBaWAVF4wNsu608.png':'http://image.wuyougoodcar.com/renhuo/2023-07-08/hZmPt5Z0eeCEhAwzEesl8x7bXIRhsr5WrQP28mQb.png' },
      { title: '03:动作绑定', url: windowWidth>996?'http://image.wuyougoodcar.com/renhuo/2023-07-08/ORw9kklr71jOiW1a0cpLz1n6pnA0irRHdRneSm40.png':'http://image.wuyougoodcar.com/renhuo/2023-07-08/eQK0n5GEjznDApYE7LuollZqgF1d7vD0XAoihkqO.png' },
      { title: '04:渲染输出', url:windowWidth>996? 'http://image.wuyougoodcar.com/renhuo/2023-07-08/2UoYfagm7bSMISDXN8oV3Yn9cr12jOgVplgQb3LS.png':'http://image.wuyougoodcar.com/renhuo/2023-07-08/weDv17ZpJZ33aEvZxxtFYsCTpnmIMqSSG2Q2Lej6.png' }
    ]
  }
  return (
    <div className="h-screen tog-container">
      <ReactFullpage render={(state) => {
        if (state.fullpageApi) {
          state.fullpageApi.setScrollingSpeed(1)
          state.fullpageApi.moveTo(1)
          state.fullpageApi.setScrollingSpeed(700)
        }
        return (
          <ReactFullpage.Wrapper>
            <div className="container-first section">
              <ToGPage1></ToGPage1>
            </div>
            <div className="section">
              <Platform platformData={platformData}></Platform>
            </div>
            <div className="section">
              <ToGPage3></ToGPage3>
            </div>
            <div className="section">
              <Cooperates className="cooperate" cooperateData={cooperateData}></Cooperates>
            </div>
            <div className="section">
              <ToGPage5></ToGPage5>
            </div>
            <div className="section">
              <JoinUs></JoinUs>
            </div>
            {
              windowWidth >= 996 ? <div className="section section-footer">
                <Footer></Footer>
              </div>:
              <div className="section">
                <Footer></Footer>
              </div>
            }
          </ReactFullpage.Wrapper>
        )
      }}>
      </ReactFullpage>
    </div>
  )
}