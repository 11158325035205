import './index.scss'
import Buy from '../Buy'
import { useState } from 'react'
export default function Cooperates({ cooperateData }) {
  const { title, abstract, list } = cooperateData
  const [show, setShow] = useState(false)
  return (
    <div className='cooperate-container h-screen page-style'>
      {
       show && <Buy type="1" handlerShow={() => setShow(false)}></Buy>
      }
      <p className='p1'>{title}</p>
      <p className='p2'>{abstract}</p>
      <div className='list'>
        {
          list.map(item => (
            <div className='list-item' key={item.id}>
              <div className='item-top'>
                <img className='image-styles' src={item.url}></img>
              </div>
              <p className='p3'>{item.title}</p>
            </div>
          ))
        }
      </div>
      <div className='btn' onClick={() => setShow(true)}>获取详细报价</div>
    </div>
  )
}