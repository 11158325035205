import './index.scss'
export default function Footer() {
  return (
    <div>
      <div className="footer-container">
        <div className="line"></div>
        <div className="footer-content">
          <div className="footer-box">
            <div className="box-left">
              <div className='left1'>
                <img className='logo' src='http://image.wuyougoodcar.com/renhuo/2023-06-29/kmiDVXXWG6noc0h5FXwWCGEo1IokE2SUiCR1jAvC.png'></img>
                <p className='p1'>我们致力于寻求解放人类劳动生产力新方案</p>
              </div>
              <div className='left2'>
                <p className='p-title'>产品服务</p>
                <p className='p-item'>数字IP</p>
                <p className='p-item'>视频生成</p>
                <p className='p-item'>数字人定制</p>
                <p className='p-item'>对话交互</p>
                <p className='p-item'>智能语音</p>
              </div>
              <div className='left3'>
                <p className='p-title'>关于星耀</p>
                <p className='p-item'>公司介绍</p>
                <p className='p-item'>新闻中心</p>
                <p className='p-item'>大事件记录</p>
              </div>
              <div>
                <p className='p-title'>联系我们</p>
                <p className='p-item'>@2833786962@qq.com</p>
              </div>
            </div>
            <div className="box-right">
              <div className='title'></div>
              <div>
                <p className='p1'>中国·杭州</p>
                <p className='p2'>杭州市萧山区博奥路机器人大厦B座3楼</p>
                <p className='p3'>173-7004-4114</p>
                <img className='qr' src='http://image.wuyougoodcar.com/renhuo/2023-06-29/p9rHxM6UGCev9jeQJ6NyBHiCnQxFoECEV3RcPVXZ.png'></img>
              </div>
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className='icp'>
          <img src="https://jmqfile.oss-cn-hangzhou.aliyuncs.com/admin/0/2023616/cdbb4c9f296244fe944ffa085c76718e.png" alt="" />
          <a href="https://beian.miit.gov.cn/?token=2aa20d12-721d-46ce-8a31-47cea8a7b8c4#/Integrated/index">浙ICP备2021026718号</a>
        </div>
      </div>
      <div className='footer-container-mobile-terminal h-screen'>
        <img className='logo' src='http://image.wuyougoodcar.com/renhuo/2023-06-29/kmiDVXXWG6noc0h5FXwWCGEo1IokE2SUiCR1jAvC.png'></img>
        <p className='p1'>我们致力于寻求解放人类劳动生产力新方案</p>
        <div className='line'></div>

        <div className='function-box'>
          <div className='left'>
            <div className='title'>产品服务</div>
            <div className='item'>数字IP</div>
            <div className='item'>视频生成</div>
            <div className='item'>数字人定制</div>
            <div className='item'>对话交互</div>
            <div className='item'>智能语音</div>
          </div>
          <div className='right'>
            <div className='title'>关于星耀</div>
            <div className='item'>公司介绍</div>
            <div className='item'>新闻中心</div>
            <div className='item'>大事件记录</div>
          </div>
        </div>
        <div className='line'></div>

        <div className='bot-item'>
          <div className='item-left'>中国·杭州</div>
          <div className='item-right'>杭州市萧山区博奥路机器人大厦B座3楼</div>
        </div>
        <div className='bot-item'>
          <div className='item-left'></div>
          <div className='item-right'>173-7004-4114</div>
        </div>
        <div className='bot-item'>
          <div className='item-left'>联系我们</div>
          <div className='item-right'>@2833786962@qq.com</div>
        </div>
        <img className='qr' src='http://image.wuyougoodcar.com/renhuo/2023-06-29/p9rHxM6UGCev9jeQJ6NyBHiCnQxFoECEV3RcPVXZ.png'></img>
        <div className='icp'>
          <img src="https://jmqfile.oss-cn-hangzhou.aliyuncs.com/admin/0/2023616/cdbb4c9f296244fe944ffa085c76718e.png" alt="" />
          <a href="https://beian.miit.gov.cn/?token=2aa20d12-721d-46ce-8a31-47cea8a7b8c4#/Integrated/index">浙ICP备2021026718号</a>
        </div>
      </div>
    </div>

  )
}