import './index.scss'
import Platform from '../components/Platform/Platform';
import ReactFullpage from '@fullpage/react-fullpage';
import JoinUs from '../components/JoinUs/JoinUs';
import Footer from '../components/Footer/Footer';
import Cooperates from '../components/Cooperates/Cooperates';
function ToBPage1() {
  const toLaihua = () => {
    window.open('https://www.laihua.com/')
  }
  return (
    <div className="tob-page-1 h-screen page-style">
      <div className='page-content h-screen page-style'>
        <img className='logo' src='http://image.wuyougoodcar.com/renhuo/2023-06-29/htX9hM5KEtraQqXMd3SEJHDdtfnFLBvwyXJe1MOw.png'></img>
        <input placeholder='刘畊宏数字人IP' className='input-style'></input>
        <div className="btn" onClick={toLaihua}>开始试用</div>
        <div className='list'>
          <img className='list-item' src='http://image.wuyougoodcar.com/renhuo/2023-06-29/GlNxmqGrxxMZZSvwJ3KCsJYhtOrTCyB6zOUTP2Jd.png'></img>
          <img className='list-item' src='http://image.wuyougoodcar.com/renhuo/2023-06-29/3M4SQbVpBULwW7fddXZ9uDBSaPNftC1v52SCJf3v.png'></img>
          <img className='list-item' src='http://image.wuyougoodcar.com/renhuo/2023-06-29/XVFy5blOkAsUNczejFtyuUyHFUTmrTNEM5qTb5Nk.png'></img>
          <img className='list-item' src='http://image.wuyougoodcar.com/renhuo/2023-06-29/KvrOSp7Q1lFWjd7AMoj3VgS0vRWPySAr1ttTDVNP.png'></img>
          <img className='list-item' src='http://image.wuyougoodcar.com/renhuo/2023-06-29/imx0jWibPzan4mrgJ8ZRQBxDMyknwNotKIyvrLjr.png'></img>
        </div>
      </div>
    </div>
  )
}
function ToBPage3() {
  return (
    <div className='tob-page-3 h-screen page-style'>
      <p className='p1'>海量达人明星数字人IP资源</p>
      <p className='p2'>针对行业痛点，使用AI数字人赋能商业行为</p>
      <div className='list'>
        <div className='item1'>
          达人
        </div>
        <div className='item2'>
          素人
        </div>
        <div className='item3'>
          明星
        </div>
        <div className='item4'>
          公模
        </div>
        <div className='item5'>
          深度定制人
        </div>
      </div>
      <p className='p3'>AI数字人IP，赋能所有中大型商家！</p>
    </div>
  )
}
export default function ToB() {
  const platformData = {
    title: '星耀数字人IP-版权交易平台',
    abstract: '星耀AI数字成立于2019年08月，总部位于中国杭州，是一家以服务智能化和知识智能化为核心的人工智能创新企业。',
    content: '星耀AI作为全球第一家开放数字人 IP买卖，接受多种合作方式，通过打造开放开源，集体进步，任劳任怨的星耀数字劳动力，为客户提供近乎无限供给的数字员工。大幅度降低组织成本，快速提升组织效率，让客户在数字化转型中拥有以算力为核心的创新竞争力是星耀最终想得到的结果。',
    list: [
      {
        value: '15+', title: '技术专利', content: '15+技术专利，只为提供更专业的行业解决方案', id: 1
      },
      {
        value: '40000+', title: '覆盖行业', content: '40000+企业合作，让AI商业应用场景更为真实且广泛', id: 2
      },
      {
        value: '100+', title: '团队实力', content: '100+行业内精尖团队成员，以技术赋能更多AI商业应用落地', id: 3
      },
    ]
  }
  const cooperateData = {
    title: '多种合作方式',
    abstract: '灵活获取数字人IP服务',
    list: [
      { id: 1, title: '按日收费及续费', url: 'http://image.wuyougoodcar.com/renhuo/2023-06-29/tFcBxZ5lDQOmufvCGzujNURz8dcPsPUWW8mpTsML.png' },
      { id: 2, title: '按月收费及续费', url: 'http://image.wuyougoodcar.com/renhuo/2023-06-29/U2m6rfynmaqmpJW0osHR6SwLzzQv5YlfwElQdDj5.png' },
      { id: 3, title: '按季度/3个月收费及续费', url: 'http://image.wuyougoodcar.com/renhuo/2023-06-29/c1NHJsbegJt0aUyfroap5uID94OqZyXT1PZqgmaq.png' },
      { id: 4, title: '按年收费及续费', url: 'http://image.wuyougoodcar.com/renhuo/2023-06-29/aI19hv4bpNAYsIvPjMA2HwmDjSnraxc93ojazJFp.png' }
    ]
  }
  return (
    <div className="h-screen tob-container">
      <ReactFullpage licenseKey="OPEN-SOURCE-GPLV3-LICENSE" render={(state) => {
        if (state.fullpageApi) {
          state.fullpageApi.setScrollingSpeed(1)
          state.fullpageApi.moveTo(1)
          state.fullpageApi.setScrollingSpeed(700)
        }
        const windowWidth = window.innerWidth
        const height = window.innerHeight
        return (
          <ReactFullpage.Wrapper>
            <div className="container-first section">
              <ToBPage1></ToBPage1>
            </div>
            <div className="section">
              <Platform platformData={platformData}></Platform>
            </div>
            <div className="section">
              <ToBPage3></ToBPage3>
            </div>
            <div className="section">
              <Cooperates cooperateData={cooperateData}></Cooperates>
            </div>
            <div className="section">
              <JoinUs></JoinUs>
            </div>
            {
              windowWidth >= 996 ? <div className="section section-footer">
                <Footer></Footer>
              </div> :
                <div className="section">
                  <Footer></Footer>
                </div>
            }
          </ReactFullpage.Wrapper>
        )
      }}>
      </ReactFullpage>
    </div>
  )
}