import './index.scss'
import Footer from '../components/Footer/Footer'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import SearchBox from '../components/SearchBox';
import PopularityItem from '../components/PopularityItem';
import { useNavigate } from 'react-router-dom';
import { virtuallyPeople, rank, popularityList, recommend } from '../../api'
import { useEffect, useState } from 'react';
function SwiperDom1() {
  const toLaihua = () => {
    window.open('https://www.laihua.com/aigcshare')
  }
  return <Swiper className='w-full swiper1-box'>
    <SwiperSlide>
      <div className='h-full w-full swiper1-item'>
        <p className='p1'>星耀数字人IP-版权交易平台！</p>
        <p className='p2'>快去购买并制作属于自己的数字人吧！</p>
        <div className='btn' onClick={toLaihua}>立刻制作</div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div className='h-full w-full swiper1-item'>
        <p className='p1'>星耀数字人IP-版权交易平台！</p>
        <p className='p2'>快去购买并制作属于自己的数字人吧！</p>
        <div className='btn' onClick={toLaihua}>立刻制作</div>
      </div>
    </SwiperSlide>
  </Swiper>
}
function SwiperDom2() {
  const navigate = useNavigate()
  const [virtuallyPeopleAry, setVirtuallyPeopleAry] = useState([])
  useEffect(() => {
    getVirtuallyPeople()
  }, [])
  const getVirtuallyPeople = async () => {
    const res = await recommend({ pageSize: 10, pageNum: 1 })
    setVirtuallyPeopleAry(res.data)
  }
  const toMsg = row => {
    navigate('/starlight/detail', { state: { id: row.id } })
  }
  return <Swiper slidesPerView={4} className='swiper2-box'>
    {
      virtuallyPeopleAry.map(item => (
        <SwiperSlide key={item.id}>
          <div onClick={() => toMsg(item)} style={{ background: `url(${item.image_url}) no-repeat 0 0`, backgroundSize: '100% 100%' }} className='swiper2-item h-full'></div>
        </SwiperSlide>
      ))
    }
  </Swiper>
}
function Rank() {
  const [rankAry1, setRankAry1] = useState([])
  const [rankAry2, setRankAry2] = useState([])
  useEffect(() => {
    console.log('xxxx');
    getRank()
  }, [])
  const getRank = async () => {
    const res1 = await rank({ pageSize: 5, pageNum: 1 })
    const res2 = await rank({ pageSize: 5, pageNum: 2 })
    setRankAry1(res1.data)
    setRankAry2(res2.data)
  }
  return <div className='rank-box'>
    <div className='rank-side'>
      <div className='title-box'>
        <p className='item1'>收藏</p>
        <p className='item2'>收藏人数</p>
        <p className='item3'>价格</p>
      </div>
      {
        rankAry1.map((item, index) => {
          return <div className='content-box' key={item.id}>
            <div className='item1'>
              {index + 1}
              <img className='avatar' src={item.image_url}></img>
              {item.name}
            </div>
            <div className='item2'>{item.collect_num}</div>
            <div className='item3'>{item.month_money}</div>
          </div>
        })
      }
    </div>
    <div className='rank-side hidden'>
      <div className='title-box'>
        <p className='item1'>收藏</p>
        <p className='item2'>收藏人数</p>
        <p className='item3'>价格</p>
      </div>
      {
        rankAry2.map((item, index) => {
          return <div className='content-box' key={item.id}>
            <div className='item1'>
              {index + 1}
              <img className='avatar' src={item.image_url}></img>
              {item.name}
            </div>
            <div className='item2'>{item.collect_num}</div>
            <div className='item3'>{item.month_money}</div>
          </div>
        })
      }
    </div>
  </div>
}
export default function Starlight() {
  const [popularityAry, setPopularityAry] = useState([])
  const [findAry, setFindAry] = useState([])
  useEffect(() => {
    console.log('aaaaaaaaaaa');
    getPopularityData()
    getFindAry()
  }, [])
  const getFindAry = async () => {
    const res = await virtuallyPeople({ pageNum: 1, pageSize: 8 })
    setFindAry(res.data)
  }
  const getPopularityData = async () => {
    const res = await popularityList({ pageNum: 1, pageSize: 8 })
    setPopularityAry(res.data)
  }
  const navigate = useNavigate()
  const toRank = () => {
    navigate('/starlight/rank')
  }
  const toPopularity = () => {
    navigate('/starlight/popularity')
  }
  const toFind = () => {
    navigate('/starlight/find')
  }
  const toRecommend = () => {
    navigate('/starlight/recommend')
  }
  return (
    <div className="starlight hidden-scroll">
      <div className='starlight-content-box'>
        <div className='starlight-content hidden-scroll'>
          <SearchBox></SearchBox>
          <SwiperDom1></SwiperDom1>
          <div className='recommend'>
            <p className='p1'>精选推荐</p>
            <div className='more' onClick={toRecommend}>更多 {'>>'}</div>
          </div>
          <SwiperDom2></SwiperDom2>
          <div className='rank'>
            <p className='rank-title'>排名</p>
            <div className='rank-right'>
              <div className='time'>24h</div>
              <div className='more' onClick={toRank}>更多 {'>>'}</div>
            </div>
          </div>
          <Rank></Rank>
          <div className='recommend'>
            <p className='p1'>更受欢迎的</p>
            <div className='more' onClick={toPopularity}>更多 {'>>'}</div>
          </div>
          <div className='popularity'>
            {
              popularityAry.map(item => <PopularityItem key={item.id} popularityData={item}></PopularityItem>)
            }
          </div>
          <div className='recommend'>
            <p className='p1'>发现</p>
            <div className='more' onClick={toFind}>更多 {'>>'}</div>
          </div>
          <div className='popularity'>
            {
              findAry.map(item => <PopularityItem key={item.id} popularityData={item}></PopularityItem>)
            }
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  )
}