
import './index.scss'
import Buy from '../Buy'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
export default function PopularityItem({ popularityData }) {
  const { name, source, month_money, image_url,id } = popularityData
  const [show,setShow] = useState(false)
  const navigate = useNavigate()
  const toMsg = (e) => {
    e.stopPropagation()
    navigate('/starlight/detail',{state:{id}} )
  }
  const buy = e => {
    e.stopPropagation()
    setShow(true)
  }
  const handlerShow = (e,a) => {
    setShow(false)
  }
  return (
    <div className='popularity-item' onClick={toMsg}>
      {
        show &&  <Buy handlerShow={handlerShow}></Buy>
      }
      {/* <div className='heart'>
        <img src='http://image.wuyougoodcar.com/renhuo/2023-06-30/sjHnbCpR9iSWZGTvtSToUS3Yf00yprIcO9bwiV5Y.png'></img>
      </div> */}
      <img className='main-img' src={image_url}></img>
      <div>

      <p className='p1'>{name}</p>
      <p className='p2'>{source}
        <img src='http://image.wuyougoodcar.com/renhuo/2023-06-30/2AL0AOLJVProkgloFSbgMAfFn7O7oVwd1HUGAL6C.png'></img>
      </p>
      <div className='bot'>
        <p className='p3'>￥{month_money || 0}</p>
        <div className='btn' onClick={buy}>购买</div>
      </div>
      </div>
    </div>
  )
}