import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import ToB from './pages/ToB/ToB';
import ToC from './pages/ToC/ToC';
import ToG from './pages/ToG/ToG';
import Technology from './pages/Technology/Technology';
import Menu from './pages/components/Menu/Menu';
import Starlight from './pages/Starlight/Starlight';
import Rank from './pages/Rank';
import Detail from './pages/Detail';
import Popularity from './pages/Popularity';
import Find from './pages/Find';
import Recommend from './pages/Recommend';
function App() {
  window.localStorage.setItem('innerWidth', window.innerWidth)
  return (
    <div className="App">
      <Menu></Menu>
      <Routes>
        <Route path='/' element={<Home></Home>}></Route>
        <Route path='/technology' element={<Technology></Technology>}></Route>
        <Route path='/starlight' element={<Starlight></Starlight>}></Route>
        <Route path='/tob' element={<ToB></ToB>}></Route>
        <Route path='/toc' element={<ToC></ToC>}></Route>
        <Route path='/tog' element={<ToG></ToG>}></Route>
        <Route path='/starlight/rank' element={<Rank></Rank>}></Route>
        <Route path='/starlight/detail' element={<Detail></Detail>}></Route>
        <Route path='/starlight/popularity' element={<Popularity></Popularity>}></Route>
        <Route path='/starlight/find' element={<Find></Find>}></Route>
        <Route path='/starlight/recommend' element={<Recommend></Recommend>}></Route>
      </Routes>
    </div>
  );
}

export default App;
