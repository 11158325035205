
import { useState } from 'react'
import './index.scss'
import { useNavigate } from 'react-router-dom'
export default function SearchBox({handlerSearch}) {
  const [val,setVal] = useState('')
  const handlerVal = e => {
    const values = e.target.value
    setVal(values)
    handlerSearch(values)
  }
  const navigate = useNavigate()
  const toHome = () => {
    navigate('/')
  }
  const toLaihua = () => {
    window.open('https://www.laihua.com/aigcshare')
  }
  return (
    <div className='top-box'>
      <img className='search-icon' src='http://image.wuyougoodcar.com/renhuo/2023-06-30/Qt9cdUAaZi6jP26tZHnTT8UfbsKVqeNZfPUl3wim.png' />
      <input value={val} onChange={handlerVal} className='input-style' placeholder='刘畊宏、周杰伦'></input>
      <p onClick={toHome}>主页</p>
      <p onClick={toLaihua}>生成</p>
      {/* <img className='image' src='http://image.wuyougoodcar.com/renhuo/2023-06-30/qt2pWwyQddwRsew5clpjIxeq2prQn0wZtdRCZPqO.png'></img> */}
      {/* <img className='image' src='http://image.wuyougoodcar.com/renhuo/2023-06-30/dobvYcczcFNqU5EGAsLX6DRdwiBp2BALFXhumKEM.png'></img> */}
      {/* <img className='image' src='http://image.wuyougoodcar.com/renhuo/2023-06-30/RYXTK27Jg9wYa4715cQCeatE76oo4Rr2jZxlaH79.png'></img> */}
    </div>
  )
}