import './index.scss'
import PopularityItem from '../components/PopularityItem'
import Footer from '../components/Footer/Footer'
import Buy from '../components/Buy'
import { useEffect, useState } from 'react'
import { recommend, virtuallyPeople, detail } from '../../api'
import { useLocation, useNavigate } from 'react-router-dom'
export default function Detail() {
  const location = useLocation()
  console.log(location);
  useEffect(() => {
    getRecommendData()
    getFindData()
  }, [])
  useEffect(() => {
    getDetail()
  }, [location.state.id])
  const [detailData, setDetailData] = useState({
    image_url: '',
    name: "",
    source: '',
    month_money: 0,
    year_money: 0
  })
  const [recommendAry, setRecommendAry] = useState([])
  const [findAry, setFindAry] = useState([])
  const getDetail = async () => {
    const res = await detail({ id: location.state.id })
    setDetailData(res.data)
  }
  const getRecommendData = async () => {
    const res = await recommend({ pageNum: 1, pageSize: 8 })
    setRecommendAry(res.data)
  }
  const getFindData = async () => {
    const res = await virtuallyPeople({ pageNum: 1, pageSize: 8 })
    setFindAry(res.data)
  }
  const [show, setShow] = useState(false)
  const navigate = useNavigate()
  const toPopularity = () => {
    navigate('/starlight/popularity')
  }
  const toFind = () => {
    navigate('/starlight/find')
  }
  return (
    <div className='recommend-page'>
      {
        show && <Buy handlerShow={() => setShow(false)}></Buy>
      }
      <div className='recommend-container'>
        <div className='top-box'>
          <img className='left-img' src={detailData.image_url} alt="" />
          <div className='right-box'>
            <div className='p1'>{detailData.name}</div>
            <div className='tips-box'>
              <span>{detailData.source}</span>
              <img src="http://image.wuyougoodcar.com/renhuo/2023-07-11/QTLZ1vsjVMnRPtnZM1csn7H3Zo9UK09fkR5MkD59.png" alt="" />
            </div>
            <div className='top-content'>
              <div className='cost'>
                <div className='moon'>
                  <div className='p2'>按月付费</div>
                  <div className='p3'>￥{detailData.month_money || 0}</div>
                  <img src="http://image.wuyougoodcar.com/renhuo/2023-07-11/lQ7rC9NZwx8klWbW81sE4uPBoF2EouIToAGaGoUy.png" alt="" />
                </div>
                <div className='year'>
                  <div className='p2'>按年付费</div>
                  <div className='p3'>￥{detailData.year_money || 0}</div>
                  <img src="http://image.wuyougoodcar.com/renhuo/2023-07-11/uLj2xu6xTf9n1ho6ItVlC5DLpfojKx0IcamNtzl0.png" alt="" />
                </div>
              </div>
              <div className='btn-box'>
                <div className='buy-btn' onClick={() => setShow(true)}>
                  <img src="http://image.wuyougoodcar.com/renhuo/2023-07-13/4rR3Nu0iCRDgqMIDl8twxgVnOwocTZkQ9aXONmKg.png" alt="" />
                  联系客服定制购买
                </div>
              </div>
              <div className='times-box'>
                <div className='times-side'>
                  <div className='p4'>浏览次数</div>
                  <div className='p5'>{detailData.browse_num || 0}</div>
                </div>
                <div className='times-center'>
                  <div className='p4'>购买次数</div>
                  <div className='p5'>{detailData.buy_num || 0}</div>
                </div>
                <div className='times-side'>
                  <div className='p4'>收藏次数</div>
                  <div className='p5'>{detailData.collect_num || 0}</div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className='title-box'>
          <div className='title-name'>更受欢迎的</div>
          <div className='title-btn' onClick={toPopularity}>
            更多 {'>>'}
          </div>
        </div>
        <div className='popularity-box'>
          {
            recommendAry.map(item => <PopularityItem key={item.id} popularityData={item}></PopularityItem>)
          }
        </div>
        <div className='title-box'>
          <div className='title-name'>发现</div>
          <div className='title-btn' onClick={toFind}>
            更多 {'>>'}
          </div>
        </div>
        <div className='popularity-box' style={{ marginBottom: '21px' }}>
          {
            findAry.map(item => <PopularityItem key={item.id} popularityData={item}></PopularityItem>)
          }
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}