import './index.scss'
import { pushDemand } from '../../../api'
import { useState } from 'react'
export default function JoinUs() {
  const [query, setQuery] = useState({
    name: '',
    number: '',
    content: ''
  })
  const submit = async () => {
    if (!query.name) return alert('请填写您的称呼！')
    if (!query.number) return alert('请填写您的联系方式！')
    if (!query.content) return alert('请填写您的需求！')
    const res = await pushDemand(query)
    alert('需求已提交！')
    setQuery({
      name: '',
      number: '',
      content: ''
    })
  }
  return (
    <div className='joinus-container w-full page-style'>
      <p className='p1'>发起需求</p>
      <p className='p2'>我们能做什么？我们能改变什么？我们能创造什么？</p>
      <div className='input-box w-full'>
        <input className='input-style' value={query.name} onChange={e => setQuery({ ...query, name: e.target.value })} placeholder='您的称呼' />
        <input className='input-style' value={query.number} onChange={e => setQuery({ ...query, number: e.target.value })} placeholder='您的联系方式' />
      </div>
      <textarea value={query.content} onChange={e => setQuery({ ...query, content: e.target.value })} className='text-area-style w-full' placeholder='留下您的需求，获取免费解决方案或报价信息。' />
      <div className='btn' onClick={submit}>加入我们</div>
    </div>
  )
}