import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './index.scss'
function MenuItem({ handlerShow }) {
  const windonWidth = window.innerWidth
  const location = useLocation()
  const navigate = useNavigate()
  const menuAry = [
    { id: 1, title: '首页', path: '/' },
    { id: 2, title: '技术优势', path: '/technology' },
    { id: 3, title: '星耀IP', path: '/starlight' },
    { id: 4, title: 'TOB', path: '/tob' },
    { id: 5, title: 'TOC', path: '/toc' },
    { id: 6, title: 'TOG', path: '/tog' },
  ]

  const [tabIndex, setTabIndex] = useState(() => {
    const { pathname } = location
    if (!pathname) return 1
    const index = menuAry.findIndex((e, index) => {
      if (index !== 0) {
        return pathname.indexOf(e.path) !== -1
      }
    })
    if (index === -1) {
      return 1
    } else {
      return menuAry[index].id
    }
  })

  useEffect(() => {
    const { pathname } = location
    if (!pathname) return setTabIndex(1)
    const index = menuAry.findIndex((e, index) => {
      if (index !== 0) {
        return pathname.indexOf(e.path) !== -1
      }
    })
    if (index === -1) {
      return setTabIndex(1)
    } else {
      return setTabIndex(menuAry[index].id)
    }
  }, [location.pathname])

  const handlerMenu = (item) => {
    navigate(item.path)
    setTabIndex(item.id)
    handlerShow(false)
  }
  return (
    windonWidth > 996 ?
      menuAry.map(item => {
        return <div onClick={() => handlerMenu(item)} className={`menu-item ${item.id === tabIndex && 'menu-item-active'}`} key={item.id}>{item.title}</div>
      }) :
      menuAry.map(item => {
        return <div key={item.id} onClick={() => handlerMenu(item)} className='item'>{item.title}</div>
      })
  )
}
export default function Menu() {
  const [show, setShow] = useState(false)
  const windonWidth = window.innerWidth
  const toLogin = () => {
    window.open('https://www.laihua.com/')
  }
  return (
    <div className='menu-container'>
      {
        windonWidth > 996 ?
          <img alt='' className='logo' src="http://image.wuyougoodcar.com/renhuo/2023-06-29/AKD4gvsuoBo74uJ2itMiHlVceFCklh1xGvX026Zu.png" />
          : <img alt='' className='logo' src='http://image.wuyougoodcar.com/renhuo/2023-07-13/9Q8i3hXWGgxtJNDEMXpvLjxsiZFUbDiY2UCqPjvS.png'></img>
      }

      <div className='menu-box'>
        <MenuItem handlerShow={e => setShow(e)}></MenuItem>
        {/* <div className='login-btn' onClick={toLogin}>登录</div> */}
        <a className='login-btn' href='http://yiwise-app.oss-cn-hangzhou.aliyuncs.com/oem/xingyao/prod/win32/%E6%98%9F%E8%80%80%E6%95%B0%E6%99%BA_1.0.5.exe'>下载客户端</a>
      </div>
      <img className='menu-btn' onClick={() => { setShow(!show) }} src='http://image.wuyougoodcar.com/renhuo/2023-06-29/SsL0WyQoBsscUaUWT9pO5fwpGM6fjgQEk1bDRzDR.png'></img>
      {
        windonWidth <= 996 &&
        <div className={`menu-mobile ${show && 'show-menu'}`}>
          <MenuItem handlerShow={e => setShow(e)}></MenuItem>
        </div>
      }
    </div>
  )
}