import './index.scss'
import Footer from '../components/Footer/Footer'
import { rank } from '../../api'
import { useEffect, useState } from 'react'
export default function Rank() {
  const [ary1, setAry1] = useState([])
  const [ary2, setAry2] = useState([])
  const getAry = async () => {
    const res1 = await rank({ pageNum: 1, pageSize: 12 })
    const res2 = await rank({ pageNum: 2, pageSize: 12 })
    setAry1(res1.data)
    setAry2(res2.data)
  }
  useEffect(() => {
    getAry()
  }, [])
  const innerWidth = window.innerWidth
  return (
    <div>
      <div className="rank-container">
        <div className='page-title'>
          <span>排名</span>
        </div>

        <div className='rank-box'>
          <div className='rank'>
            <div className='rank-title'>
              <span className='collect'>收藏</span>
              <span className='collect-people'>收藏人数</span>
              <span className='price'>价格</span>
            </div>
            {
              ary1.map((item, index) =>
                <div className='rank-item' key={index}>
                  <div className='collect'>
                    <span className='item-index'>{index + 1}</span>
                    <img className='avatar' src={item.image_url} alt="" />
                    <span className='item-name'>{item.name}</span>
                    <img className='item-icon' src="http://image.wuyougoodcar.com/renhuo/2023-07-10/q5KF3zmENe49j0qmf6JdKxR4TAl9hbylTY9Qfy0c.png" alt="" />
                  </div>
                  <div className='collect-people'>{item.collect_num}</div>
                  <div className='price'>{item.month_money}</div>
                </div>
              )
            }

          </div>
          <div className='rank'>
            {
              innerWidth > 996 && <div className='rank-title'>
                <span className='collect'>收藏</span>
                <span className='collect-people'>收藏人数</span>
                <span className='price'>价格</span>
              </div>
            }

            {
              ary2.map((item, index) =>
                <div className='rank-item' key={index}>
                  <div className='collect'>
                    <span className='item-index'>{index + 1}</span>
                    <img className='avatar' src={item.image_url} alt="" />
                    <span className='item-name'>{item.name}</span>
                    <img className='item-icon' src="http://image.wuyougoodcar.com/renhuo/2023-07-10/q5KF3zmENe49j0qmf6JdKxR4TAl9hbylTY9Qfy0c.png" alt="" />
                  </div>
                  <div className='collect-people'>{item.collect_num}</div>
                  <div className='price'>{item.month_money}</div>
                </div>
              )
            }

          </div>
        </div>

        <p className='p1'>........</p>
        <p className='p2'>排名只显示前24位</p>
        <img className='logo' src="http://image.wuyougoodcar.com/renhuo/2023-07-10/vPoeHh0VlF5NXA1MNHsZioIyWRheRdSoq7x2Ggse.png" alt="" />
      </div>
      <Footer />
    </div>

  )
}