import './index.scss'
import Platform from '../components/Platform/Platform';
import ReactFullpage from '@fullpage/react-fullpage';
import JoinUs from '../components/JoinUs/JoinUs';
import Footer from '../components/Footer/Footer';
import Cooperates from '../components/Cooperates/Cooperates';
function ToCPage1() {
  const toLaihua = () => {
    window.open('https://www.laihua.com/')
  }
  return (
    <div className="toc-page-1 h-screen page-style">
      <div className='bag w-screen h-screen'></div>
      <div className='content w-screen h-screen page-style'>
        <img className='logo' src='http://image.wuyougoodcar.com/renhuo/2023-06-30/jDsprpeSpm1vRKYry6aj8NqPEokeHKOKXGVH04FP.png'></img>
        <p className='p1'>您的私人专属数字人专家，不懂就问。</p>
        <div className="btn" onClick={toLaihua}>立即体验</div>
      </div>
    </div>
  )
}
function ToCPage3() {
  return (
    <div className='toc-page-3 h-screen page-style'>
      <p className='p1'>核心技术</p>
      <p className='p2'>针对行业痛点，使用AI数字人赋能商业行为</p>
      <img className='image' src='http://image.wuyougoodcar.com/renhuo/2023-07-02/HORevh1tASKVdhiLRt2wgnWwshrgNHTqcpHjYPY6.png'></img>
    </div>
  )
}
function ToCPage4() {
  return (
    <div className='toc-page-4 h-screen page-style'>
      <p className='p1'>星画-模型业务流程</p>
      <p className='p2'>针对行业痛点，使用AI数字人赋能商业行为</p>

      <img className='image1' src='http://image.wuyougoodcar.com/renhuo/2023-07-02/1h358Jl1KW4brGgYqlrUKskgDwIgg4TjMYUNMnwh.png'></img>
      <img className='image2' src='http://image.wuyougoodcar.com/renhuo/2023-07-02/EyMusXkACzIIOx5lCPVYtPlqqD3DtUlZFDpSmtfG.png'></img>
      <p className='p3'>交付形式：星画根据所需文本数据，训练个性化垂直小模型与通用语言大模型结合，输出垂直
        领域大模型的智能助手H5产品</p>
    </div>
  )
}
export default function ToC() {
  const platformData = {
    title: '星画-AIGC语言知识科普应用',
    abstract: '星画成立于2019年08月，总部位于中国杭州，是一家以知识智能化为核心的人工智能创新企业。',
    content: '星画联合国内Ai第一梯队来画科技，打造开放开源，集体进步的星画aigc数字语音交互为主的知识科普平台，旨在为每个人类提供贴身的AI数字专家。大幅度降低知识获取成本，快速提升知识传播效率，让客户在数字化转型中拥有以算力为核心的创新竞争力是星画最终想得到的结果。',
    list: [
      {
        value: '15+', title: '技术专利', content: '15+技术专利，只为提供更专业的行业解决方案', id: 1
      },
      {
        value: '40000+', title: '覆盖行业', content: '40000+企业合作，让AI商业应用场景更为真实且广泛', id: 2
      },
      {
        value: '100+', title: '团队实力', content: '100+行业内精尖团队成员，以技术赋能更多AI商业应用落地', id: 3
      },
    ]
  }
  const cooperateData = {
    title: '多种合作方式',
    abstract: '灵活获取数字人IP服务',
    list: [
      { id: 1, title: '按日收费及续费', url: 'http://image.wuyougoodcar.com/renhuo/2023-06-29/tFcBxZ5lDQOmufvCGzujNURz8dcPsPUWW8mpTsML.png' },
      { id: 2, title: '按月收费及续费', url: 'http://image.wuyougoodcar.com/renhuo/2023-06-29/U2m6rfynmaqmpJW0osHR6SwLzzQv5YlfwElQdDj5.png' },
      { id: 3, title: '按季度/3个月收费及续费', url: 'http://image.wuyougoodcar.com/renhuo/2023-06-29/c1NHJsbegJt0aUyfroap5uID94OqZyXT1PZqgmaq.png' },
      { id: 4, title: '按年收费及续费', url: 'http://image.wuyougoodcar.com/renhuo/2023-06-29/aI19hv4bpNAYsIvPjMA2HwmDjSnraxc93ojazJFp.png' }
    ]
  }
  const windowWidth = window.innerWidth
  return (
    <div className="h-screen toc-container">
      <ReactFullpage licenseKey="OPEN-SOURCE-GPLV3-LICENSE" render={(state) => {
        if (state.fullpageApi) {
          state.fullpageApi.setScrollingSpeed(1)
          state.fullpageApi.moveTo(1)
          state.fullpageApi.setScrollingSpeed(700)
        }
        return (
          <ReactFullpage.Wrapper>
            <div className="container-first section">
              <ToCPage1></ToCPage1>
            </div>
            <div className="container-first section">
              <Platform platformData={platformData}></Platform>
            </div>
            <div className="container-first section">
              <ToCPage3></ToCPage3>
            </div>
            <div className="container-first section">
              <ToCPage4></ToCPage4>
            </div>
            <div className="container-first section">
              <Cooperates cooperateData={cooperateData}></Cooperates>
            </div>
            <div className="container-first section">
              <JoinUs></JoinUs>
            </div>
            {
              windowWidth >= 996 ? <div className="section section-footer">
                <Footer></Footer>
              </div> :
                <div className="section">
                  <Footer></Footer>
                </div>
            }
          </ReactFullpage.Wrapper>
        )
      }}>
      </ReactFullpage>
    </div>
  )
}