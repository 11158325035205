import request from "./request";

// 加入我们
export function pushDemand(data) {
  return request({
    url: '/demand/pushDemand',
    data,
    method: 'POST'
  })
}
// 数字人
export function virtuallyPeople(data) {
  return request({
    url: '/aiPeople/aiPeopleList',
    method: 'POST',
    data
  })
}

// 排行榜
export function rank(data) {
  return request({
    url: '/aiPeople/rankingList',
    method: 'POST',
    data
  })
}

// 受欢迎的
export function popularityList(data) {
  return request({
    url: '/aiPeople/popularList',
    method: 'POST',
    data
  })
}


// 推荐
export function recommend(data) {
  return request({
    url: '/aiPeople/recommendList',
    method: 'POST',
    data
  })
}

// 详情
export function detail(data){
  return request({
    url:'/aiPeople/detail',
    method:'POST',
    data
  })
}