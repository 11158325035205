import './index.scss'
import SearchBox from '../components/SearchBox'
import PopularityItem from '../components/PopularityItem'
import Footer from '../components/Footer/Footer'
import { recommend } from '../../api'
import { useEffect, useState } from 'react'
export default function Recommend() {
  const [ary, setAry] = useState([])
  const [query, setQuery] = useState({ pageNum: 1, pageSize: 16, keyworld: '' })
  useEffect(() => {
    getAry()
  }, [query])
  const getAry = async () => {
    const res = await recommend(query)
    setAry(res.data || [])
  }
  const handlerSearch = e => {
    setQuery({ ...query, keyworld: e })
  }
  return (
    <div className='find-page'>
      <div className='find-container'>
        <SearchBox handlerSearch={handlerSearch}></SearchBox>
        <p className='find-p1'>精选推荐</p>
        <div className='find-box'>
          {
            ary.length > 0 && ary.map(item => <PopularityItem key={item.id} popularityData={item} />)
          }
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}